// Packages
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../../node_modules/slick-carousel/slick/slick.css'

// Assets
import useData from '../../../hooks/useData'

// Styled components
import { StyledCertificatesCarousel, StyledSlideContainer, StyledFluiImage, StyledTitle, StyledWrapper } from './styledComponents'

const CertificatesCarousel = props => {
  const { constants: { sliderCommonSettings } } = useData()

  const sliderSettings = {
    ...sliderCommonSettings,    
    slidesToShow: 4,
    centerMode: false,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          centerMode: true,
          slidesToShow: 3
        }
      }
    ]
  }

  const { certificatesImages } = useStaticQuery(
    graphql`
      {
        certificatesImages: allFile(filter: { sourceInstanceName: { eq: "content" }, relativeDirectory: { eq: "certificates-images" } }) {
          nodes {
            childImageSharp {              
              fluid(maxWidth: 270, maxHeight: 270) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  )

  const slides = certificatesImages.nodes.map(edge => {
    return {
      fluid: edge.childImageSharp.fluid
    }
  })
  
  const slidesRendered = slides.map((slide, index) => (
    <StyledSlideContainer key={index}>
      <StyledFluiImage fluid={slide.fluid} alt='' />
    </StyledSlideContainer>
  ))

  return (
    <StyledCertificatesCarousel {...props}>
      <StyledWrapper>      
        <StyledTitle>
          Estamos certificados por:
        </StyledTitle>
        <Slider {...sliderSettings}>
          {slidesRendered}
        </Slider>
      </StyledWrapper>
    </StyledCertificatesCarousel>
  )
}

export default CertificatesCarousel
