// Packages
import React from 'react'

// Styled components
import { StyledServiceList, StyledServiceOptions, StyledServiceListTitle, StyledServiceListTitleWrapper, StyledServiceOptionsContainer, StyledParagraph } from './styledComponents'

const ServiceList = ({ title, description }) => {
  return (
    <StyledServiceList>
      <StyledServiceListTitleWrapper>
        <StyledServiceListTitle>{title}</StyledServiceListTitle>
      </StyledServiceListTitleWrapper>
      <StyledServiceOptionsContainer>
        <StyledServiceOptions>
          <StyledParagraph>{description}</StyledParagraph>
        </StyledServiceOptions>        
      </StyledServiceOptionsContainer>
    </StyledServiceList>
  )
}

export default ServiceList
