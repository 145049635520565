// Packages
import styled from 'styled-components'

export const StyledServicesPageServicesList = styled.section``

export const StyledWrapper = styled.div`
  padding: 2rem 0;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`

export const StyledSectionTitle = styled.h3`
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    font-size: 1.7rem;
  }
`

export const StyledLists = styled.div`  
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.75rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    column-gap: 1rem;
    max-width: 90%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 85%;
  }
`

export const StyledSectionTitleContainer = styled.div`
  padding: 0 1rem;
`
