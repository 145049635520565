// Packages
import React from 'react'

// Styled components
import { StyledEnterpiseTrust, StyledWrapper, StyledSectionTitle, StyledCircle, StyledCircles, StyledLogo } from './styledComponents'

// Assets
import ManpowerLogo from '../../../data/assets/img/logos/manpower.png'
import PepsaLogo from '../../../data/assets/img/logos/pepsa.png'
import SensaLogo from '../../../data/assets/img/logos/Sensa_logo.png'
import SupercreamLogo from '../../../data/assets/img/logos/supercream.png'
import UnileverLogo from '../../../data/assets/img/logos/unilever.png'

const EnterpiseTrust = props => {
  return (
    <StyledEnterpiseTrust {...props}>
      <StyledWrapper>
        <StyledSectionTitle>Estas empresas han confiado en nosotros</StyledSectionTitle>
        <StyledCircles>
          <StyledCircle>
            <StyledLogo src={ManpowerLogo} />
          </StyledCircle>
          <StyledCircle>
            <StyledLogo src={PepsaLogo} />
          </StyledCircle>
          <StyledCircle>
            <StyledLogo src={SensaLogo} />
          </StyledCircle>
          <StyledCircle>
            <StyledLogo src={SupercreamLogo} />
          </StyledCircle>
          <StyledCircle>
            <StyledLogo src={UnileverLogo} />
          </StyledCircle>
        </StyledCircles>
      </StyledWrapper>
    </StyledEnterpiseTrust>
  )
}

export default EnterpiseTrust
