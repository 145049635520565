// Packages
import React from 'react'

// Components
import ServiceList from '../ServiceList'

// Styled components
import { StyledServicesPageServicesList, StyledWrapper, StyledSectionTitle, StyledLists, StyledSectionTitleContainer } from './styledComponents'

// Assets
import useData from '../../../hooks/useData'

const ServicesPageServicesList = () => {
  const { constants: { servicesList } } = useData()

  const listsRendered = servicesList.map((service, index) => {
    return (
      <ServiceList key={service.title + index} title={service.title} description={service.description} />
    )
  })

  return (
    <StyledServicesPageServicesList>      
      <StyledWrapper>
        <StyledSectionTitleContainer>
          <StyledSectionTitle>Brindamos los siguientes servicios para cuidar de ti y de tu familia.</StyledSectionTitle>
        </StyledSectionTitleContainer>
        <StyledLists>
          {listsRendered}          
        </StyledLists>
      </StyledWrapper>
    </StyledServicesPageServicesList>
  )
}

export default ServicesPageServicesList
