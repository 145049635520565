// Packages
import styled from 'styled-components'

// Components
import Button from '../Button'

export const StyledServicePageSupport = styled.section`
  margin: 1.5rem 0;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    margin: 0;
  }
`

export const StyledWrapper = styled.div`
  align-items: center;  
  display: inline-grid;
  grid-template-columns: auto 1fr;
  justify-content: center;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
    max-width: 90%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 100%;
  }
`

export const StyledButton = styled(Button)`
  box-shadow: 0px 3px 6px #00000029;
  font-size: 0.85rem;  
`

export const StyledTitle = styled.h3`
  font-family: 'PT Sans', sans-serif;  
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0.95rem;
  text-align: left;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    text-align: center;
  }
`

export const StyledImage = styled.img`
  height: 100%;  
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 15rem;
    min-height: 12rem;
  }
`

export const StyledImageWrapper = styled.div`
  height: 10rem;
  width: 13rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`

export const StyledTitleWrapper = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
