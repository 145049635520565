// Packages
import styled from 'styled-components'

// Components
import CertificatesCarousel from '../CertificatesCarousel'
import ServicePageSupport from '../ServicePageSupport'

export const StyledServicesPageContent = styled.div``

export const StyledMergedSections = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    display: flex;
    justify-content: center;
  }
`

export const StyledMergedSectionsWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 95%;
    align-items: center;    
    display: flex;
    flex-direction: row-reverse;    
    justify-content: center;    
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
`

export const StyledCertificatesCarousel = styled(CertificatesCarousel)`
  @media (min-width: ${props => props.theme.breakpoints.large}px) { 
    width: 50%;
  }
`

export const StyledServicePageSupport = styled(ServicePageSupport)`
  @media (min-width: ${props => props.theme.breakpoints.large}px) { 
    width: 50%;
  }
`
