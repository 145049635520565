// Packages
import styled from 'styled-components'

// Components
import FluidImage from '../FluidImage'

export const StyledSlideContainer = styled.div`
  max-width: calc(100% - 0.2rem);
  width: 5rem !important;

  @media (min-width: ${props => props.theme.breakpoints.small}px) {
    max-width: calc(100% - 0.25rem);
    width: 8.5rem !important;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 100%;
    width: 6rem !important;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 100%;
    width: 5rem !important;
  }
`

export const StyledFluiImage = styled(FluidImage)`  
  max-height: 100%;
  max-width: 100%;
  opacity: .8;
  transform: scale(.95);
  transition: all .3s ease;
`

export const StyledWrapper = styled.div`
  padding: 1rem 0rem 1rem 1rem;

  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide.slick-center {
          ${StyledFluiImage} {
            opacity: 1;
            transform: scale(1.05);
          }
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.small}px) {
    max-width: 90%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 100%;
    padding: 0;
  }
`

export const StyledCertificatesCarousel = styled.section`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.small}px) { 
    display: flex;
    justify-content: center;
  }
`

export const StyledTitle = styled.h3`
  font-family: 'PT Sans', sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
`
