// Packages
import React from 'react'
import { graphql } from 'gatsby'

// Components
import AppLayout from '../../layouts/AppLayout'
import SEO from '../../components/presentational/SEO'
import ServicesPageContent from '../../components/presentational/ServicesPageContent'

const ServicesPage = ({ data }) => {  
  const banners = [
    {
      mobileImage: data.firstBannerMobile.childImageSharp.fluid,
      desktopImage: data.firstBannerDesktop.childImageSharp.fluid,
      to: null,
      onClick: () => {        
        typeof window !== 'undefined' && window.open('https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n', '_blank') // formulario de cotizacion
      }
    },
    {
      mobileImage: data.secondBannerMobile.childImageSharp.fluid,
      desktopImage: data.secondBannerDesktop.childImageSharp.fluid,
      to: null,
      onClick: () => {
        typeof window !== 'undefined' && window.open('https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n', '_blank') // formulario de cotizacion
      }
    }    
  ]

  return (
    <AppLayout>
      <SEO title='Servicios' />
      <ServicesPageContent banners={banners} />
    </AppLayout>
  )
}

export const query = graphql`
  query ServicesPageQuery {  

    firstBannerMobile: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "services-banners/mobile/banner_1.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    secondBannerMobile: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "services-banners/mobile/banner_2.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    firstBannerDesktop: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "services-banners/desktop/banner_1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    secondBannerDesktop: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "services-banners/desktop/banner_2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`

export default ServicesPage
