// Packages
import React from 'react'

// Styled components
import { StyledServicesPageContent, StyledMergedSections, StyledMergedSectionsWrapper, StyledCertificatesCarousel, StyledServicePageSupport } from './styledComponents'

// components
import Hero from '../Hero'
import ServicesPageCovidDisclaimer from '../ServicesPageCovidDisclaimer'
import ServicesPageServicesList from '../ServicesPageServicesList'
import EnterpriseTrust from '../EnterpriseTrust'

const ServicesPageContent = ({ banners }) => {
  return (
    <StyledServicesPageContent>
      <Hero slides={banners} />
      <ServicesPageCovidDisclaimer />
      <ServicesPageServicesList />
      <StyledMergedSections>
        <StyledMergedSectionsWrapper>
          <StyledCertificatesCarousel />
          <StyledServicePageSupport />
        </StyledMergedSectionsWrapper>
      </StyledMergedSections>
      <EnterpriseTrust />
    </StyledServicesPageContent>
  )
}

export default ServicesPageContent
