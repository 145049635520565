// Packages
import React from 'react'

// Styled components
import { StyledServicesPageCovidDisclaimer, StyledWrapper, StyledButton, StyledSectionTitle, StyledSectionTitleContainer, StyledParagraph, StyledButtonContainer, StyledProductsContainer } from './styledComponents'

const ServicesPageCovidDisclaimer = () => {
  return (
    <StyledServicesPageCovidDisclaimer>
      <StyledWrapper>
        <StyledSectionTitleContainer>
          <StyledSectionTitle>Check up COVID Evaluación</StyledSectionTitle>
          <StyledProductsContainer>
            <StyledParagraph>Dímero D</StyledParagraph>
            <StyledParagraph>Ferritina</StyledParagraph>
            <StyledParagraph>Biometría hemática (BH)</StyledParagraph>
          </StyledProductsContainer>
        </StyledSectionTitleContainer>
        <StyledButtonContainer>
          <StyledButton
            onClick={() => {
              typeof window !== 'undefined' && window.open('https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n', '_blank')
            }}
          >            
            Agenda tu cita
          </StyledButton>
        </StyledButtonContainer>
      </StyledWrapper>
    </StyledServicesPageCovidDisclaimer>
  )
}

export default ServicesPageCovidDisclaimer
