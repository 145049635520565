// Packages
import React from 'react'

// Styled components
import { StyledServicePageSupport, StyledWrapper, StyledButton, StyledTitle, StyledImage, StyledImageWrapper, StyledTitleWrapper } from './styledComponents'

// Assets
import Image from '../../../data/assets/img/entrepreneurs-group.jpg'

const ServicePageSupport = props => {
  return (
    <StyledServicePageSupport {...props}>
      <StyledWrapper>
        <StyledTitleWrapper>
          <StyledTitle>Si necesitas apoyo para tu regreso a la nueva normalidad o procesos de recursos humanos.</StyledTitle>
          <StyledButton>Me interesa una cotización</StyledButton>
        </StyledTitleWrapper>
        <StyledImageWrapper>
          <StyledImage src={Image} />
        </StyledImageWrapper>
      </StyledWrapper>
    </StyledServicePageSupport>
  )
}

export default ServicePageSupport
