// Package
import styled from 'styled-components'

// Components
import Button from '../Button'

export const StyledServicesPageCovidDisclaimer = styled.section`
  background-color: rgba(174, 234, 227, .5); 
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  mix-blend-mode: multiply;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
  }
`

export const StyledWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto 10rem;
  padding: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 90%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {    
    max-width: 80%;
  }

  @media (min-width: ${props => props.theme.breakpoints.xLarge}px) {
    max-width: 75%;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxLarge}px) {
    max-width: 70%;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxxLarge}px) {
    max-width: 65%;
  }
`

export const StyledButton = styled(Button)`
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.5rem;
  height: 3rem;
  width: 10rem;
`

export const StyledSectionTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 0.75rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    align-items: center;
    display: flex;
    font-size: 2rem;
    margin: 0;
    height: 100%;
  }
`

export const StyledSectionTitleContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: inline-grid;
    grid-template-columns: auto 1fr;
  }
`

export const StyledParagraph = styled.p`
  line-height: 1.3;  
  text-align: center;
  width: 100%;
`

export const StyledButtonContainer = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center;
`

export const StyledProductsContainer = styled.div``
