// Packages
import styled from 'styled-components'

export const StyledEnterpiseTrust = styled.section`
  background-color: rgba(111, 235, 200, .38);
  margin: 1rem 0;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
  }
`

export const StyledWrapper = styled.div`
  padding: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 90%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 85%;
  }
`

export const StyledCircle = styled.div`
  background-color: #fff;
  border-radius: 50px;
  height: 4rem;
  text-align: center;
  display: block;
  width: 4rem;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    height: 5rem;
    width: 5rem;
  }
`

export const StyledLogo = styled.img`
  height: 2.75rem;
  margin-top: 0.5rem;
  width: 2.75rem;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    height: 3.2rem;
    margin-top: 0.7rem;
    width: 3.2rem;
  }
`

export const StyledSectionTitle = styled.h3`
  font-family: 'PT Sans', sans-serif;
  font-size: 1.2rem;  
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
`

export const StyledCircles = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    justify-content: space-evenly;
  }
`
