// Packages
import styled from 'styled-components'

export const StyledServiceList = styled.ul``

export const StyledServiceOptions = styled.li`
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.xxxxSmall}px) { 
    justify-content: space-around;
  }
`

export const StyledParagraph = styled.p`
  line-height: 1.3;
  text-align: left;
  width: 100%;
`

export const StyledServiceOptionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;  
  padding: 1rem;  
`

export const StyledServiceListTitleWrapper = styled.div`
  background-color: rgba(111, 235, 200, 0.36);
  padding: 0.5rem 1rem;
`

export const StyledServiceListTitle = styled.h4`
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.3;  
  text-align: left;
  width: 100%;
`
